import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useToggle } from "hooks-me";

import {
  DISPLAY_ERROR,
  SET_CURRENT_PERSONID,
  SET_FORM_DATA,
} from "core/redux/reducer/main.reducer";
import { POONA_PAYMENT_METHODS } from "core/enums/paymentMethods";
import PoonaService from "services/poona/poona.service";
import RenewalService from "services/renewal/renewal.service";
import { buildDefaultDataForAdherent } from "utils/renewal/default.data";
import { scrollToTop } from "utils/dom/dom.utils";
import { isMajor } from "utils/string/string.utils";

import { withLayout } from "HOC";
import {
  useRegistrationKeyGeneration,
  useInstance,
  useFetchConsents,
  useRenewalLicence,
} from "hooks";
import useData from "hooks/useData";
import Wizard, { WizardElement } from "components/Wizard";
import Loading from "components/Loading";
import Header from "./components/Header";
import DisplayNotification from "./components/DisplayNotification";

import Step01 from "./components/Steps/Step01";
import Step02 from "./components/Steps/Step02";
import Step03 from "./components/Steps/Step03";
import Step04 from "./components/Steps/Step04";
import Step05 from "./components/Steps/Step05";
import Step06 from "./components/Steps/Step06";
import Step07 from "./components/Steps/Step07";

import "./styles.scss";
import MainFooter from "components/MainFooter";

const poonaService = new PoonaService();
const renewalService = new RenewalService();
const DEFAULT_STEP_INDEX = 0;
const STEP_ENDSCREEN_INDEX = 6;
const STEP_KEYS = {
  INFORMATIONS: 0,
  CONTACTS: 1,
  HEALTH: 2,
  AUTHORIZATION: 3,
  PAYMENT: 4,
  PAYMENT_METHOD: 5,
  COMPLETED: 6,
};

const mustBypassStep2OnPrevious = (currentIndex: number, data: any) => {
  return currentIndex === STEP_KEYS.HEALTH && checkIfMajor(data);
};

const checkIfMajor = (data: any) => {
  return (
    data &&
    data.step1 &&
    data.step1.informations &&
    isMajor(data.step1.informations.birthDate)
  );
};

const LicenceRenewal: React.FC = () => {
  useFetchConsents();
  const registrationKeyGenerationIsLoading = useRegistrationKeyGeneration();
  const dispatch = useDispatch();
  const history = useHistory();
  const { key, completedState } = useParams<{
    key?: string;
    completedState?: string;
  }>();
  const keyForLicenceRenewal = useRenewalLicence();
  const [isLoading, setIsLoading] = useToggle(false);
  const [membershipId, setMembershipId] = useState<string>();
  const [isLoadingUserData, setIsLoadingUserData] = useToggle(true);
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_STEP_INDEX);
  const data = useData();
  const isRenewal =
    keyForLicenceRenewal != null && keyForLicenceRenewal.length !== 0;
  const instance = useInstance();
  const defaultPropsForSteps = {
    onPrevious: () => {
      if (mustBypassStep2OnPrevious(currentIndex, data)) {
        setCurrentIndex((old) => old - 2);
      } else {
        setCurrentIndex((old) => old - 1);
      }

      scrollToTop("root", 100);
    },
    onNext: () => {
      setCurrentIndex((old) => old + 1);

      scrollToTop("root", 100);
    },
  };

  const setData = (payload: any) => {
    dispatch({ type: SET_FORM_DATA, payload });
  };

  const handleOnSubmit = useCallback(() => {
    if (!instance) {
      return;
    }

    setIsLoading(true);

    renewalService
      .sendAllRenewalData(data, instance.instanceId)
      .promise.then((response) => {
        setMembershipId(response.membershipId);
      })
      .catch((err) => {
        const { message = "Une erreur est survenue" } = err;
        const builtErrorMessage = message
          .split("<br />")
          .slice(0, 2)
          .join("<br />");

        dispatch({
          type: DISPLAY_ERROR,
          payload: { message: builtErrorMessage },
        });
        setIsLoading(false);
      });
  }, [data, dispatch, instance, setIsLoading]);

  useEffect(() => {
    if (!membershipId || !instance) {
      return;
    }

    const {
      paymentMethodId = POONA_PAYMENT_METHODS.other,
    } = data.step6.payment_methods;
    const initials = instance.initials.toLowerCase();

    if (paymentMethodId !== POONA_PAYMENT_METHODS.cb) {
      switch (paymentMethodId) {
        case POONA_PAYMENT_METHODS.virement:
          history.push(`/${initials}/fin-formulaire/virement`);
          break;
        case POONA_PAYMENT_METHODS.other:
          history.push(`/${initials}/fin-formulaire/autres`);
          break;
        default:
          history.push(`/${initials}/fin-formulaire/autres`);
          // http://localhost:3001/madbad27/fin-formulaire/completed
          break;
      }
      return;
    }

    const { promise, cancel } = renewalService.initPayment(
      { instanceId: instance.instanceId, membershipId },
      {
        urlCallback: window.location.origin + `/${initials}/fin-formulaire`,
      }
    );

    promise
      .then(({ bankTransactionUrl }) => {
        if (bankTransactionUrl) {
          window.location.href = bankTransactionUrl;
        }
      })
      .catch((err) => {
        const { message = "Impossible d'initialiser le paiement" } = err;
        dispatch({
          type: DISPLAY_ERROR,
          payload: { message },
        });
        setIsLoading(false);
      });

    return () => cancel();
    // eslint-disable-next-line
  }, [membershipId, instance, dispatch]);

  const steps: WizardElement[] = [
    {
      title: "1. Informations",
      content: <Step01 {...defaultPropsForSteps} />,
    },
    {
      title: "2. Contacts",
      content: <Step02 {...defaultPropsForSteps} />,
    },
    {
      title: "3. Santé",
      content: <Step03 {...defaultPropsForSteps} />,
    },
    {
      title: "4. Autorisations",
      content: <Step04 {...defaultPropsForSteps} />,
    },
    {
      title: "5. Tarif",
      content: <Step05 {...defaultPropsForSteps} />,
    },
    {
      title: "6. Paiement",
      content: <Step06 onSubmit={handleOnSubmit} {...defaultPropsForSteps} />,
    },
    {
      title: "7. Terminé !",
      content: <Step07 />,
    },
  ];

  /*
   * If `completedState` was provided, we redirect to the last step and bypass all other stuffs.
   */
  useEffect(() => {
    if (!!completedState || key === "fin-formulaire") {
      setCurrentIndex(STEP_ENDSCREEN_INDEX);
    }
  }, [completedState, key]);

  useEffect(() => {
    if (!keyForLicenceRenewal) {
      setData(buildDefaultDataForAdherent());
      setIsLoadingUserData(false);
      return;
    }

    setIsLoadingUserData(true);

    const { promise, cancel } = poonaService.personByLicence(
      keyForLicenceRenewal
    );
    promise
      .then((adherent) => {
        const defaultDataWithAdherentData = buildDefaultDataForAdherent(
          adherent
        );

        dispatch({
          type: SET_CURRENT_PERSONID,
          payload: adherent.personId,
        });

        setData(defaultDataWithAdherentData);
        setIsLoadingUserData(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR,
          payload: {
            message: "Impossible de récupérer vos informations",
          },
        });

        console.log(
          "err failed to fetch person with licence",
          keyForLicenceRenewal
        );
        history.push(`/${instance?.initials}`);
      });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyForLicenceRenewal, dispatch, history, instance]);

  if (isLoading || isLoadingUserData || registrationKeyGenerationIsLoading) {
    const message = isLoading
      ? "Chargement du formulaire en cours..."
      : "Chargement de vos informations personnelles...";

    return <Loading fullscreen>{message}</Loading>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adhésions - {instance?.name}</title>
      </Helmet>

      <div className="licence-renewal-container">
        <DisplayNotification />
        <Header isRenewal={isRenewal} />

        <main role="main">
          <Wizard
            steps={steps}
            index={currentIndex}
            onIndexChange={setCurrentIndex}
            lockedSteps={getLockedSteps({ currentIndex })}
          />
        </main>
        <MainFooter />
      </div>
    </>
  );
};

export default withLayout(LicenceRenewal);

const getLockedSteps = (args: { currentIndex: number }): number[] => {
  const { currentIndex } = args;

  if (currentIndex === STEP_ENDSCREEN_INDEX) {
    return [
      STEP_KEYS.INFORMATIONS,
      STEP_KEYS.CONTACTS,
      STEP_KEYS.HEALTH,
      STEP_KEYS.AUTHORIZATION,
      STEP_KEYS.PAYMENT,
      STEP_KEYS.COMPLETED,
    ];
  }

  return [];
};
